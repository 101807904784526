<template>
  <b-container>
    <b-embed
      :src="url"
      type="iframe"
      aspect="16by9"
      allowfullscreen
      lazy
    />
  </b-container>
</template>

<script>
import { BEmbed } from 'bootstrap-vue';

import metabase from '@/service/reports/reportsMetaBase';

export default {
    components: {
        BEmbed,
    },

    props: {
        code: {
            type: Number,
            default: () => 0,
        },

        question: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            url: '',
        };
    },

    created() {
        this.url = metabase(this.code, this.question);
    },
};
</script>
