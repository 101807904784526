<template>
  <b-container fluid>
    <metabase
      :code="3156"
      :question="true"
    />
  </b-container>
</template>
<script>
import {
    BContainer,
} from 'bootstrap-vue';

import Metabase from '../components/Metabase.vue';

export default {
    components: {
        BContainer,
        Metabase,
    },
};
</script>
<style lang="scss" scoped>
</style>
